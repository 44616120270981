











































































import {Goods} from "@/request/goods/Goods";
import {Advertise} from "@/request/marketing/Advertise";

export default {
    name: "el-add-banner",
    props: {},
    data() {
        let self: any = this;
        return {
            searchLoading: false,
            modal: {
                show: false
            },
            row: {
                id: '',
                type: 0
            },
            attach: {},
            list: []
        }
    },
    created() {
        let self: any = this;
    },
    watch: {
        'row.type'() {
            let self: any = this;
            self.remoteSearch(self.attach.name);
        }
    },
    methods: {
        show(row: any) {
            let self: any = this;
            self.modal.show = true;
            self.row = row;
            self.attach = {...row.attach};
            self.remoteSearch(self.attach.name);
        },
        remoteSearch(query: string) {
            let self: any = this;
            switch (self.row.type) {
                case 0:
                    self.attach = {...self.row.attach}
                    break;
                case 1:
                    self.searchGoods(query);
                    break;
                case 2:
                    self.searchActivity(query);
                    break;
            }
        },
        onChange(value: string) {
            let self: any = this;
            switch (self.row.type) {
                case 1:
                    self.attach = self.addGoods.list.filter((j: any) => j.id === value)[0] || {...self.row.attach};
                    break;
                case 2:
                    self.attach = self.addActivity.list.filter((j: any) => j.id === value)[0] || {...self.row.attach};
                    break;
            }
        },
        searchGoods(name: string) {
            let self: any = this;
            self.searchLoading = true;
            Goods.getAll(`?name=${name}`).then((body: any) => {
                self.searchLoading = false;
                self.list = body.data;
            })
        },
        searchActivity(name: string) {
            let self: any = this;
            self.searchLoading = true;
            Advertise.getAll(`?name=${name}`).then((body: any) => {
                self.searchLoading = false;
                self.list = body.data;
            })
        },
        onOk() {
            let self: any = this;
            self.row.attach = {...self.attach};
            self.$emit('on-ok', {...self.row})
        },
        uploadSuccess(url: string) {
            let self: any = this;
            self.row.image = url;
            self.$forceUpdate();
        }
    }
}
