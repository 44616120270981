



























import {FirstPage} from "@/request/shop/firstPage";
import el_add_banner from '@/components/first-page-components/el-add-banner.vue'

export default {
    name: "Banner1List",
    props: {
        id: {
            type: String
        }
    },
    data() {
        let self: any = this;
        return {
            list: []
        }
    },
    components: {
        'el-add-banner': el_add_banner
    },
    created() {
        let self: any = this;
        self.getData();
    },
    methods: {
        getData() {
            let self: any = this;
            FirstPage.getBannerList(self.id).then((body: any) => {
                if (body['code'] === 0) {
                    self.list = body.data;
                }
            })
        },
        add() {
            let self: any = this;
            self.$refs['add-banner'].show({
                type: 0, attach: {}
            });
        },
        edit(row: any) {
            let self: any = this;
            self.$refs['add-banner'].show({...row});
        },
        onSubmit(row: any) {
            let self: any = this;
            FirstPage.saveBanner({
                pageId: self.id,
                id: row.id,
                image: row.image,
                sort: row.sort,
                type: row.type,
                attach: JSON.stringify({id: row.attach.id})
            }).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                }
            })
        },
        remove(id: string) {
            let self: any = this;
            FirstPage.delBanner(id).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                }
            })
        }
    }
}
